/* module-user-get.css */

/* ---------------- */
/* USER GET STYLES  */
/* ---------------- */

.user-get-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /*  */
  padding: 0px;
  margin: -10px 10px 10px 10px;
  /*  */
  width: 95%;
  min-height: 60px;
  /*  */
  /* background-color: #fff;
  color: #1f1f1f; */
  /*  */
  border: 0px solid #ffffff80;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /*  */
  box-sizing: border-box; /* Include padding and border in width and height */
  flex-wrap: wrap; /* Allow items to wrap on small screens */
}

.user-get-span-left {
  align-items: flex-start;
}

.user-get-span-right {
  align-items: flex-end;
}

.user-get-span {
  /*  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  /*  */
  padding: 10px 16px;
  margin: 0 4px;
  /*  */
  min-width: fit-content;
  min-height: 10px;
  /*  */
  font-size: small;
  line-height: 1.5;
  /*  */
  background-color: #fff;
  color: #1f1f1f;
  /*  */
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-sizing: border-box; /* Include padding and border in width and height */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-get-btn {
  /*  */
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  /*  */
  height: 40px;
  /*  */
  /* padding: 10px 6px 10px 6px; */
  margin: 0 4px;
  /*  */
  font-size: small;
  /*  */
  background-color: WHITE;
  border: 1px solid #747775;
  border-radius: 30px;
  box-sizing: border-box;
  color: #1f1f1f;
  /*  */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /*  */
  font-size: small;
  letter-spacing: 0.2px;
  /*  */
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  cursor: pointer;
}

.user-get-btn-google-sign-in {
  /*  */
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  /*  */
  width: 220px;
  height: 40px;
  /*  */
  /* padding: 10px 6px 10px 6px; */
  margin: 0 4px;
  margin-top: 20px;
  /*  */
  font-size: small;
  /*  */
  background-color: WHITE;
  border: 1px solid #747775;
  border-radius: 30px;
  box-sizing: border-box;
  color: #1f1f1f;
  /*  */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /*  */
  font-size: small;
  letter-spacing: 0.2px;
  /*  */
  /* transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s; */

  /* Add background image */
  background-image: url("../images/googleSignIn_button.png");
  background-size: cover; /* Ensure the image covers the button */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  cursor: pointer;
}

/* ---------------- */
/* MEDIA QUERIES    */
/* ---------------- */

@media (max-width: 768px) {
  .user-get-container {
    flex-direction: column;
    align-items: center;
    margin: 10px 10px;
  }

  .user-get-span {
    width: 100%;
    margin: 10px 0;
  }

  .user-get-btn,
  .user-get-btn-google-sign-in {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
  }

  .user-get-btn-google-sign-in {
    width: 220px;
    margin-top: 30px;
  }
}
