/* module-user-makes.css */

/* -------------------------------- */
/* USER MAKES/RESUMES: TABLE       */
/* -------------------------------- */

/* Layout for Table Container */
.user-makes-table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #000;
  margin: 0 auto;
  box-sizing: border-box;
  table-layout: auto;
}

/* Table Header Styling */
th {
  background-color: #e0e0e0;
  color: #333;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 4px;
  margin-bottom: 4px;
  text-align: center;
}

tr:first-child th:first-child {
  border-top-left-radius: 8px;
  padding-left: 10px;
}

tr:first-child th:last-child {
  border-top-right-radius: 8px;
  padding-right: 20px;
}

/* Table Row and Cell Styling */
tr {
  font-size: 13px;
  text-align: center;
}

td {
  padding: 10px;
  background-color: #00000005;
  text-align: center;
}

tr:not(:last-child) td {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}

/* DATE HEADER */
.date-header {
  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding: 6px 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  text-align: center;
}

.date-header:hover {
  background-color: #e0e0e0;
  color: #000;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* -------------------------------- */
/* USER MAKES/RESUMES: BUTTON      */
/* -------------------------------- */

/* Makes Table Button */
.user-makes-btn {
  background: #3c7add;
  padding: 6px 10px;
  color: #ffffff;
  border: solid 1px #ffffff;
  border-radius: 3.125rem;
  font-size: x-small;
  font-weight: 700;
  text-align: center;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-width: 100%;
}

/* All Makes GDrive Button */
.user-makes-btn-all {
  align-items: space-evenly;
  vertical-align: middle;
  margin-top: 20px;
  padding: 10px 20px;
  background: #ffffff;
  color: #000000;
  border: solid 1px #ffffff;
  border-radius: 6px;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  max-width: 100%;
}

.user-makes-btn-all-span {
  vertical-align: middle;
  margin: 6px;
}

/* -------------------------------- */
/* USER MAKES/RESUMES: ICONS       */
/* -------------------------------- */

/* Icons */
.icon {
  width: auto;
  height: 28px;
  vertical-align: middle;
  padding: 0;
  transition: transform 0.2s ease, filter 0.2s ease;
}

.icon:hover {
  transform: scale(1.1);
  filter: brightness(0.8);
  cursor: pointer;
}

/* -------------------------------- */
/* OTHER */
/* -------------------------------- */

.base-resume-default-check {
  font-size: 18px;
}

/* -------------------------------- */
/* MEDIA QUERIES FOR RESPONSIVENESS */
/* -------------------------------- */

@media (max-width: 600px) {
  .user-makes-table {
    font-size: 12px;
  }

  th,
  td {
    font-size: 12px;
    padding: 8px;
  }

  .user-makes-btn {
    font-size: 10px;
    padding: 4px 6px;
  }

  .icon {
    height: 24px;
  }

  .user-makes-btn-all {
    font-size: 12px;
    padding: 8px 12px;
  }

  .date-header {
    font-size: 13px;
    padding: 8px;
  }
}
