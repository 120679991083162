.main-container {
  box-sizing: border-box;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: block;
  box-shadow: 0 0 10px #0000001a;
}

.module-placeholder {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-bottom: 20px;
  overflow-x: auto;
}

.dashboard-module-header {
  color: #1453b8;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  width: 96%;
  padding: 8px 16px;
  transition: transform .2s, filter .2s;
  display: inline-block;
}

.dashboard-module-header:hover {
  filter: brightness(.9);
  cursor: pointer;
  transform: scale(1.02);
}

@media (width <= 600px) {
  .main-container {
    padding: 10px;
  }

  .dashboard-module-header {
    width: 100%;
    font-size: 1rem;
  }
}

#modalBackdrop {
  backdrop-filter: blur(5px);
  z-index: 999;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#setupResumeFormContainer {
  z-index: 1000;
  color: #fff;
  cursor: pointer;
  background: #1453b8;
  border: none;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  padding: 30px 30px 40px;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px #0000001a;
}

#setupResumeFormContainer input[type="text"], #setupResumeFormContainer input[type="url"] {
  width: 90%;
  margin-top: 5px;
  padding: 10px;
}

.setup-resume-form-status {
  text-align: center;
  height: 20px;
  margin-top: 30px;
}

.btn-setup-resume {
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-get-container {
  box-sizing: border-box;
  border: 0 solid #ffffff80;
  border-radius: 10px;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  min-height: 60px;
  margin: -10px 10px 10px;
  padding: 0;
  display: flex;
}

.user-get-span-left {
  align-items: flex-start;
}

.user-get-span-right {
  align-items: flex-end;
}

.user-get-span {
  vertical-align: middle;
  text-align: center;
  color: #1f1f1f;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  min-width: fit-content;
  min-height: 10px;
  margin: 0 4px;
  padding: 10px 16px;
  font-size: small;
  line-height: 1.5;
  display: flex;
  box-shadow: 0 0 10px #0000001a;
}

.user-get-btn {
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  color: #1f1f1f;
  letter-spacing: .2px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #747775;
  border-radius: 30px;
  height: 40px;
  margin: 0 4px;
  font-size: small;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

.user-get-btn-google-sign-in {
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  color: #1f1f1f;
  letter-spacing: .2px;
  cursor: pointer;
  background-color: #fff;
  background-image: url("googleSignIn_button.a458f977.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #747775;
  border-radius: 30px;
  width: 220px;
  height: 40px;
  margin: 20px 4px 0;
  font-size: small;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

@media (width <= 768px) {
  .user-get-container {
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .user-get-span {
    width: 100%;
    margin: 10px 0;
  }

  .user-get-btn, .user-get-btn-google-sign-in {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
  }

  .user-get-btn-google-sign-in {
    width: 220px;
    margin-top: 30px;
  }
}

.user-makes-table {
  border-collapse: separate;
  border-spacing: 0;
  color: #000;
  box-sizing: border-box;
  table-layout: auto;
  background-color: #fff;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: auto hidden;
  box-shadow: 0 0 10px #0000001a;
}

th {
  color: #333;
  text-align: center;
  background-color: #e0e0e0;
  margin-bottom: 4px;
  padding: 6px 4px;
  font-size: 15px;
  font-weight: bold;
}

tr:first-child th:first-child {
  border-top-left-radius: 8px;
  padding-left: 10px;
}

tr:first-child th:last-child {
  border-top-right-radius: 8px;
  padding-right: 20px;
}

tr {
  text-align: center;
  font-size: 13px;
}

td {
  text-align: center;
  background-color: #00000005;
  padding: 10px;
}

tr:not(:last-child) td {
  border-bottom: 1px solid #c8c8c880;
}

.date-header {
  color: #333;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  background-color: #f2f2f2;
  width: 100%;
  padding: 6px 4px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color .3s, color .3s;
}

.date-header:hover {
  color: #000;
  background-color: #e0e0e0;
  box-shadow: 0 2px 5px #0000001a;
}

.user-makes-btn {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #3c7add;
  border: 1px solid #fff;
  border-radius: 3.125rem;
  max-width: 100%;
  padding: 6px 10px;
  font-size: x-small;
  font-weight: 700;
  box-shadow: 10px 0 40px #0000001a;
}

.user-makes-btn-all {
  align-items: space-evenly;
  vertical-align: middle;
  color: #000;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  max-width: 100%;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 10px 0 40px #0000001a;
}

.user-makes-btn-all-span {
  vertical-align: middle;
  margin: 6px;
}

.icon {
  vertical-align: middle;
  width: auto;
  height: 28px;
  padding: 0;
  transition: transform .2s, filter .2s;
}

.icon:hover {
  filter: brightness(.8);
  cursor: pointer;
  transform: scale(1.1);
}

.base-resume-default-check {
  font-size: 18px;
}

@media (width <= 600px) {
  .user-makes-table {
    font-size: 12px;
  }

  th, td {
    padding: 8px;
    font-size: 12px;
  }

  .user-makes-btn {
    padding: 4px 6px;
    font-size: 10px;
  }

  .icon {
    height: 24px;
  }

  .user-makes-btn-all {
    padding: 8px 12px;
    font-size: 12px;
  }

  .date-header {
    padding: 8px;
    font-size: 13px;
  }
}

.jobSearchTerms {
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 5px;
  min-width: 180px;
  margin: .2rem 0;
  padding: 5px 20px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.gsi-material-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  letter-spacing: .25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #fff;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  outline: none;
  width: auto;
  min-width: min-content;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  position: relative;
  overflow: hidden;
}

.gsi-material-button .gsi-material-button-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 12px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.gsi-material-button .gsi-material-button-contents {
  text-overflow: ellipsis;
  vertical-align: top;
  flex-grow: 0;
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
}

.gsi-material-button .gsi-material-button-state {
  opacity: 0;
  transition: opacity .218s;
  position: absolute;
  inset: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents, .gsi-material-button:disabled .gsi-material-button-icon {
  opacity: .38;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  opacity: .12;
  background-color: #303030;
}

.gsi-material-button:not(:disabled):hover {
  box-shadow: 0 1px 2px #3c40434d, 0 1px 3px 1px #3c404326;
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  opacity: .08;
  background-color: #303030;
}

.nav-links-container {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px 20px 40px;
  font-size: xx-small;
  display: flex;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
  color: #fff;
  background: linear-gradient(#1453b8 37%, #3c7add 98%) no-repeat fixed;
  font-family: Lato, sans-serif;
  overflow: hidden scroll;
}

h1 {
  color: #1453b8;
  letter-spacing: 1px;
  background-color: #fff;
  border-radius: 10px;
  width: 95%;
  max-width: 760px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  display: inline-block;
}

.header-subtitle {
  letter-spacing: 1px;
  border-radius: 6px;
  margin-top: -20px;
  padding: 4px 8px;
}

hr {
  opacity: .8;
  border: 0;
  border-top: 1px solid #0000001a;
  border-bottom: 1px solid #ffffff4d;
  height: 0;
}

a {
  color: #1453b8;
  font-weight: bold;
  text-decoration: none;
  transition: transform .2s, filter .2s;
}

a:hover {
  filter: brightness(.8);
  cursor: pointer;
}

button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #1453b8;
  border: 1px solid #fff;
  border-radius: 3.125rem;
  max-width: 100%;
  padding: .6rem 2rem;
  font-size: 1.125rem;
  font-weight: 700;
  box-shadow: 10px 0 40px #0000001a;
}

button:hover {
  filter: brightness(.8);
  transition: all .3s;
  transform: scale(1.02);
}

input {
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  margin: .2rem 0;
  padding: 5px 20px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

textarea {
  text-align: left;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  margin: .2rem 0;
  padding: 5px 10px;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 500;
}

.btn {
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  letter-spacing: .25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  outline: none;
  width: 100%;
  min-width: 220px;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  overflow: hidden;
}

.btn-sm {
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  letter-spacing: .25px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #fff;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  outline: none;
  width: 100%;
  min-width: 140px;
  height: 40px;
  padding: 0 12px;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  overflow: hidden;
}

.btn-txt {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  transform: translateY(-10px);
}

input[type="checkbox"] {
  appearance: none;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.checkbox-container {
  text-align: left;
  cursor: pointer;
  color: #1f1f1f;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  align-items: center;
  margin: .2rem 0;
  padding: 5px 20px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  display: inline-flex;
  box-shadow: 0 4px 6px #0000001a;
}

.checkbox-container label {
  text-align: center;
  color: inherit;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 0;
}

input[type="checkbox"]:checked + .checkbox-container {
  color: #fff;
  background-color: #e0e0e0;
  border-color: #747775;
  transform: translateY(0);
  box-shadow: inset 0 1px 1px #0000001a;
}

.checkbox-container:hover {
  background-color: #e0e0e0;
}

@media (width <= 600px) {
  h1 {
    padding: 10px;
    font-size: 1.5em;
  }

  .header-subtitle {
    font-size: 1em;
  }

  .main-container {
    padding: 10px;
  }

  button {
    padding: .5rem 1rem;
    font-size: 1rem;
  }

  input, textarea {
    padding: .5rem;
    font-size: 1rem;
  }

  .btn, .btn-sm {
    min-width: unset;
    font-size: 1rem;
  }
}
/*# sourceMappingURL=index.ee4e7462.css.map */
