/* module-search-jobs.css */

/* --------------------------- */
/* SEARCH JOBS STYLES          */
/* --------------------------- */

/* INPUT */

.jobSearchTerms {
  min-width: 180px;
  /*  */
  padding: 5px 20px;
  margin: 0.2rem 0;
  /*  */
  border: solid 1px #ffffff;
  border-radius: 5px;
  /*  */
  text-align: center;
  /* display: inline-block; */
  cursor: pointer;
  /*  */
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
}
