/* styles.css */

@import "module-dashboard.css";
@import "module-setup-resume.css";
@import "module-user-get.css";
@import "module-user-makes.css";
@import "module-search-jobs.css";

@import "sign-in-buttons.css";

@import "debug.css";
@import "nav-links.css";

/* Ensure all elements use border-box sizing */
* {
  box-sizing: border-box;
}

/* ---------------- */
/* MAIN             */
/* ---------------- */

html,
body {
  height: 100%; /* Ensure both html and body elements cover full height */
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: Lato, sans-serif;
  background: linear-gradient(to bottom, #1453b8 37%, #3c7add 98%);
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensures background stays fixed */
  color: #ffffff;
}

/* HEADERS */

h1 {
  margin-top: 8px;
  background-color: #fff;
  color: #1453b8;
  padding: 10px 20px;
  letter-spacing: 1px;
  border-radius: 10px;
  width: 95%;
  max-width: 760px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.header-subtitle {
  margin-top: -20px;
  padding: 4px 8px;
  letter-spacing: 1px;
  border-radius: 6px;
}

hr {
  border: 0;
  height: 0;
  opacity: 0.8;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/* LINKS */

a {
  color: #1453b8;
  font-weight: bold;
  text-decoration: none;
  transition: transform 0.2s ease, filter 0.2s ease;
}

a:hover {
  filter: brightness(0.8);
  cursor: pointer;
}

/* ---------------- */
/* INPUTS  */
/* ---------------- */

/* BUTTONS */

button {
  background: #1453b8;
  padding: 0.6rem 2rem;
  color: #ffffff;
  border: solid 1px #ffffff;
  border-radius: 3.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  box-shadow: 10px 0 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-width: 100%;
}

button:hover {
  filter: brightness(0.8);
  transition: 0.3s;
  transform: scale(1.02);
}

/* INPUTS */

input {
  padding: 5px 20px;
  margin: 0.2rem 0;
  border: solid 1px #ffffff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  max-width: 400px;
}

textarea {
  padding: 5px 10px;
  margin: 0.2rem 0;
  border: solid 1px #ffffff;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 500;
  width: 100%;
  max-width: 400px;
}

/* BTN */

.btn {
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  text-align: center;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  min-width: 220px;
  user-select: none;
}

.btn-sm {
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 20px;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  text-align: center;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  min-width: 140px;
}

.btn-txt {
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  transform: translateY(-10px);
}

/* CHECKBOX */

/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  visibility: hidden;
}

/* Style the span container to mimic inputs and dropdowns */
.checkbox-container {
  padding: 5px 20px;
  margin: 0.2rem 0;
  border: solid 1px #ffffff;
  border-radius: 5px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
  background-color: WHITE;
  color: #1f1f1f;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow for "raised" look */
}

/* Style the label inside the checkbox-container */
.checkbox-container label {
  width: 100%; /* Make the label take up the full width of the container */
  text-align: center; /* Center the text inside the label */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  color: inherit; /* Inherit text color from container */
  cursor: pointer; /* Ensure the cursor is a pointer */
}

/* Depressed look when checkbox is checked */
input[type="checkbox"]:checked + .checkbox-container {
  background-color: #e0e0e0;
  color: #ffffff;
  border-color: #747775;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); /* Inset shadow for "depressed" look */
  transform: translateY(0px);
}

/* Hover effect for the checkbox container */
.checkbox-container:hover {
  background-color: #e0e0e0;
}

/* MEDIA QUERIES FOR RESPONSIVENESS */
@media (max-width: 600px) {
  h1 {
    font-size: 1.5em;
    padding: 10px;
  }

  .header-subtitle {
    font-size: 1em;
  }

  .main-container {
    padding: 10px;
  }

  button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  input,
  textarea {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .btn,
  .btn-sm {
    font-size: 1rem;
    min-width: unset;
  }
}
