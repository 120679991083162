/* nav-links.css */

.nav-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*  */
  margin-top: 20px;
  padding: 20px;
  padding-bottom: 40px;
  font-size: xx-small;
}

.nav-link {
  /* border: 1px solid #ffffff80;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px; */
}
