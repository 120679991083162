/* module-setup-resume.css */

/* --------------------------- */
/* SETUP RESUME MODAL STYLES    */
/* --------------------------- */

/* Modal backdrop styling */
#modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999; /* Ensures it's above everything else */
}

/* Setup Resume Form container styling */
#setupResumeFormContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1453b8;
  padding: 30px 30px 40px 30px;
  border-radius: 10px;
  z-index: 1000; /* Ensures it's above the backdrop */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  /*  */
  display: block;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

/* Add any additional styling you need for form elements */
#setupResumeFormContainer input[type="text"],
#setupResumeFormContainer input[type="url"] {
  width: 90%;
  padding: 10px;
  margin-top: 5px;
}

.setup-resume-form-status {
  margin-top: 30px;
  height: 20px;
  text-align: center;
}

.btn-setup-resume {
  margin-top: 20px;
  margin-bottom: 20px;
}
