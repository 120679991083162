/* module-dashboard.css */

/* --------------------------- */
/* DASHBOARD/MODULE ELEMENTS   */
/* --------------------------- */

/* MAIN BODY CONTAINER */
.main-container {
  display: block;
  width: 100%;
  max-width: 800px;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

/* MODULE PLACEHOLDER */

.module-placeholder {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  margin-bottom: 20px;
}

/* MODULE HEADER */

.dashboard-module-header {
  display: inline-block;
  color: #1453b8;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  transition: transform 0.2s ease, filter 0.2s ease;
  width: 96%;
  text-align: center;
  padding: 8px 16px;
}

.dashboard-module-header:hover {
  transform: scale(1.02);
  filter: brightness(0.9);
  cursor: pointer;
}

/* MEDIA QUERIES FOR RESPONSIVENESS */
@media (max-width: 600px) {
  .main-container {
    padding: 10px;
  }

  .dashboard-module-header {
    width: 100%;
    font-size: 1rem;
  }
}
